// @ts-nocheck
import React, { useState, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import httpService, { storedAccessToken } from '../../services/http';
import { Plot, useStructureContext } from '../StructureProvider';
import chartOptionConstants from '../../components/charts/constants/chartOptionConstants';
import chartDashStyles from '../../components/charts/constants/chartDashStyles';
import chartMarkerSymbols from '../../components/charts/constants/chartMarkerSymbols';
import { emitErrorMsgToaster } from '../../components/common/Toaster/index';

type Props = {
  children: React.ReactNode;
};
type ResponseType = {
  created_at: string;
  created_by: string;
  finished_at: string;
  fmu: Object;
  folder: string;
  id: number;
  inputsFMU: Object;
  is_interactive: boolean;
  name: string;
  output_interval: number;
  parameters: Object;
  parametersFMU: Object;
  start_time: number;
  started_at: string;
  stop_time: number;
  ui_results_data: { [key: string]: { name: string; data: number[][] }[] }[];
  ui_setup_data: Object[];
};

type PlotData = Array<{
  color: any;
  dashStyle: any;
  data: any;
  isMulti: boolean;
  markerSymbol: any;
  name: string;
  nrSims: any;
  patternIndex: any;
  simName: any;
  sortIndex: any;
  varName: any;
  xAxisRange: any | null;
  yAxisRange: any | null;
  key: string;
}>;

export type contextType = {
  isLoading: boolean;
  setIsLoading: (arg0) => void;
  isSimulating: boolean;
  setIsSimulating: (arg0) => void;
  resultData: any;
  uiResultsData: any;
  uiSetupData: any;
  setQuery: any;
  timeIndex: number;
  setTimeIndex: (arg0) => void;
  stopTime: Array<number>;
  outputInterval: Array<number>;
  invalidateCachedResponse: (id: number) => void;
  addPlotDataToStructure: () => void;
  plotsStructureWithData: Array<Array<{ name: string; type: string; series: Object; data: Array<Object> }>>;
};

export const PlotDataContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

const PlotDataProvider = ({ children }: Props) => {
  // states that hold data of currently selected simulations:
  // updated (plot_data and parameters) version of plot response data
  const [resultData, setResultData] = useState<ResponseType[]>([]);
  const [uiResultsData, setUiResultsData] = useState([]);
  const [uiSetupData, setUiSetupData] = useState();
  const [plotsStructureWithData, setPlotsStructureWithData] = useState<any>([[{}]]);

  const [query, setQuery] = useState([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSimulating, setIsSimulating] = useState<boolean>(false);

  const [timeIndex, setTimeIndex] = useState<number>(0);
  const [stopTime, setStopTime] = useState<number[]>([1]);
  const [outputInterval, setOutputInterval] = useState<number[]>([1]);

  const { uiResultsConfig: resultsUiConfig, initialValues } = useStructureContext();

  const queryClient = useQueryClient();

  /**
   * @description handles the uiResultsData and uiSetupData for each app
   * they are merged with up-to-date data.
   * isLoading is set false at the end.
   * @param responseData Array of uiResultsData from API response
   * @returns {*}
   */
  const handleResultData = (responseData: Array<ResponseType>) => {
    // set stop time and output interval so that the time index can be calculated in timeseries plot component
    // stop time and output interval are set for all results in case a differentiation is necessary in the future
    // in ResultsPlotComponent the first entry is used
    setStopTime(responseData.map((res) => (res.stop_time ? res.stop_time : 1)));
    setOutputInterval(responseData.map((res) => (res.output_interval ? res.output_interval * 1000 : 1)));
    // get plots from all result data
    const resultPlotData = responseData.map((r) => {
      return r.ui_results_data;
    });
    setUiResultsData([...resultPlotData]);

    // get parameters from all result data
    const resultUiParameter = responseData.map((r) => {
      return r.ui_setup_data;
    });
    // make sure new parameters (after an update) are also considered
    const completeUiParameter = resultUiParameter.map((r) => ({
      ...initialValues,
      ...r,
    }));
    if (!completeUiParameter.every((value) => value === null)) setUiSetupData(completeUiParameter);

    const results = [...responseData];
    for (let i = 0; i < results.length; i += 1) {
      results[i].parameters = completeUiParameter[i];
      results[i].ui_results_data = resultPlotData[i];
    }

    setResultData(results);
    // make sure all results are loaded
    if (resultPlotData && completeUiParameter && results) setIsLoading(false);
  };

  /**
   * @description get request for ui_results_data
   * @param queries Array of queries
   * @returns {Promise<SimulationData[]>}
   */
  const getPlotData = (queries: number[]): Promise<SimulationData[]> => {
    try {
      const promises = queries.map(async (queryID) => {
        const [simulation, simulationResult] = await Promise.all([
          httpService.get(`simulations/${queryID}/`, { headers: null }, {}, true, 'v2'),
          httpService.get(`simulations/${queryID}/results/`, { headers: null }, {}, true, 'v2'),
        ]);

        return {
          ...simulation.data,
          ui_results_data: simulationResult.data,
        };
      });

      return Promise.all(promises);
    } catch (error) {
      emitErrorMsgToaster({
        content: error.message,
      });
      return [];
    }
  };

  /**
   * Get data for one plot of each simulation
   * and handle multiple simulations by adding simulation name and adjusting styles of markers, lines etc.
   * @param name plotData of this plot gets returned
   * @param customColors array of custom colors
   * @returns {plotData}
   */
  const getSelectedPlotData = (name: string, plt: Plot, customColors?: string[]): PlotData => {
    const plotColors = customColors ? customColors.concat(chartOptionConstants.colors) : chartOptionConstants.colors;
    let orderedPlotData = [];
    const isMulti = resultData.length > 1;

    // loop through each simulation
    resultData.forEach((r, rIndex) => {
      const parsedPlotData = r.ui_results_data;
      let series = parsedPlotData[name] || parsedPlotData[plt.oldName];
      // For multiple simulation results, add simulation name, handle styles and indizes
      if (isMulti && series) {
        if (plt.type === 'interactive_svg') {
          const matchingUiResultsData = resultData.find(
            (res) => `${plt.oldName}${res.name}${res.id}` === plt.name
          )?.ui_results_data;
          series = matchingUiResultsData ? matchingUiResultsData[plt.oldName] : parsedPlotData[plt.oldName];
        } else {
          series.forEach((ser, serIndex) => {
            const serName = series.length > 1 ? `${r.name} (${ser.name})` : `${r.name}`;
            orderedPlotData.push({
              name: serName,
              data: ser.data,
              color: plotColors[rIndex],
              dashStyle: chartDashStyles[serIndex],
              markerSymbol: chartMarkerSymbols[serIndex],
              patternIndex: rIndex,
              sortIndex: rIndex,
              isMulti: true,
              varName: ser.name,
              simName: r.name,
              nrSims: resultData.length,
              xAxisRange: ser.xAxisRange ? ser.xAxisRange : null,
              yAxisRange: ser.yAxisRange ? ser.yAxisRange : null,
              key: `${serName}_${rIndex}`,
            });
          });

          series = orderedPlotData.sort((a, b) => a.sortIndex - b.sortIndex);
        }
      }
      orderedPlotData = series || [...orderedPlotData]; // in case sort didn't work
    });

    return orderedPlotData;
  };

  /**
   * @description get request for ui_setup_data
   * @param plotsCopy Array of plots
   * @returns {void}
   */
  const handleInteractivePlots = (plotsCopy: Array<Plot>) => {
    const interactiveSvgPlotId = plotsCopy.findIndex((el) => el.type === 'interactive_svg');
    const interactiveSvgPlot = plotsCopy.find((el) => el.type === 'interactive_svg');
    resultData.forEach((res, resId) => {
      const isAlreadyInPlotsArray = plotsCopy.some(
        (el) => el.name === `${interactiveSvgPlot.name}${res.name}${res.id}`
      );
      if (!isAlreadyInPlotsArray) {
        if (resId === 0) {
          // Modify the interactive svg plot that is already there (now corresponds to the first simulation)
          plotsCopy.splice(interactiveSvgPlotId, 1, {
            ...interactiveSvgPlot,
            oldName: interactiveSvgPlot.name,
            name: `${interactiveSvgPlot.name}${res.name}${res.id}`,
            isMulti: true,
            resName: res.name,
            resId,
          });
        } else {
          // Add new interactive svg plots for the other simulations
          plotsCopy.splice(interactiveSvgPlotId + resId, 0, {
            ...interactiveSvgPlot,
            oldName: interactiveSvgPlot.name,
            name: `${interactiveSvgPlot.name}${res.name}${res.id}`,
            isMulti: true,
            resName: res.name,
            resId,
          });
        }
      }
    });

    return plotsCopy;
  };

  /**
   * @description add plot data to plots structure
   * @returns {void}
   */
  const addPlotDataToStructure = () => {
    const isMulti = resultData.length > 1;
    const plotsWithDataTmp = resultsUiConfig.map((pltStr) => {
      const { plots } = pltStr;
      // copy needs to be used to not override plots -> wrong behaviour if selected simulations change
      let plotsCopy = [...plots];
      const hasInteractiveSvgPlot = plotsCopy.some((el) => el.type === 'interactive_svg');

      if (isMulti && hasInteractiveSvgPlot) {
        plotsCopy = handleInteractivePlots(plotsCopy);
      }
      const plotsToShow = isMulti ? plotsCopy : plots;
      return plotsToShow.map((plt) => ({ ...plt, data: getSelectedPlotData(plt.name, plt, plt.customColors) }));
    });
    setPlotsStructureWithData(plotsWithDataTmp);
  };

  /**
   * @description executed on change of query
   * handles cached data as well as the call for new data.
   * The data are stored as raw request data and handleResultData()
   * is called every time.
   */
  useQuery(
    ['plotData', query],
    async () => {
      if (storedAccessToken() && query.length !== 0) {
        const response = getPlotData(query);
        return response;
      }
      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          handleResultData(data);
        }
      },
    }
  );

  const invalidateCachedResponse = (id: number) => {
    queryClient.invalidateQueries(['plotData', id]);
  };

  return (
    <PlotDataContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isSimulating,
        setIsSimulating,
        resultData,
        uiResultsData,
        uiSetupData,
        setQuery,
        timeIndex,
        setTimeIndex,
        stopTime,
        outputInterval,
        invalidateCachedResponse,
        addPlotDataToStructure,
        plotsStructureWithData,
      }}
    >
      {children}
    </PlotDataContext.Provider>
  );
};

export const usePlotDataContext = (): contextType => {
  const context = useContext(PlotDataContext);

  if (context === undefined) {
    throw new Error('usePlotDataContext must be used within a PlotDataProvider');
  }

  return context;
};
export default PlotDataProvider;
