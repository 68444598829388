// @ts-nocheck
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import httpService, { storedAccessToken } from '../../services/http';
import { isEmpty } from '../../utils/helper/helper';
import { useUserDataContext } from '../UserDataProvider';
import * as unitsHelper from '../../utils/helper/units';
import { getVariableList, getInitialValues } from './helper';
import settings from '../../settings';
import type { Form, Quantity, UiSetupConfig, UiResultsConfig, Unit, Variable } from './types';

type Props = {
  children: React.ReactNode;
};

export type contextType = {
  getFmuList: (...args: Array<any>) => any;
  selectedFmu: any;
  setSelectedFmu: any;
  getFmuData: (...args: Array<any>) => any;
  fmuSelectList: Array<{
    value: number;
    label: string;
  }>;
  isLoadingStructure: boolean;
  uiSetupConfig: UiSetupConfig;
  interactiveSimulationMode: boolean;
  setInteractiveSimulationMode: any;
  inputsAvailable: boolean;
  menuLevel2: Array<Array<string>>;
  parameterMenuIcons: Array<string>;
  initialValues: any;
  variables: Array<any>;
  uiResultsConfig: UiResultsConfig;
  outputInterval: number;
  setOutputInterval: any;
  forms: Array<{
    name: string;
    forms: Array<{
      name: string;
      form: Form;
    }>;
  }>;
  interactiveForms: Array<{
    name: string;
    forms: Array<{
      name: string;
      form: Form;
    }>;
  }>;
  quantities: Array<Quantity>;
  units: Array<Unit>;
  parameterList: Array<Variable>;
  uiVariablesList: Array<Variable>;
  hiddenParameterList: Array<any>;
  fmuComponents: Array<any>;
  inputsList: Array<Variable>;
  fmuConfigurationVersion: 'v1' | 'v2';
  resultCharts: Array<any>;
};

export const StructureContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

const StructureProvider = ({ children }: Props) => {
  // FMU selection states
  const [selectedFmu, setSelectedFmu] = useState(undefined);
  const [fmuSelectList, setFmuSelectList] = useState([]);
  // FMU data states
  const [uiSetupConfig, setUiSetupConfig] = useState([]);
  const [uiInputConfig, setUiInputConfig] = useState([]);
  const [variables, setVariables] = useState([]);
  const [uiResultsConfig, setUiResultsConfig] = useState([]);
  const [teams, setTeams] = useState([]);
  const [forms, setForms] = useState([]);
  const [interactiveForms, setInteractiveForms] = useState([]);
  const [interactiveSimulationMode, setInteractiveSimulationMode] = useState(false);
  const [inputsAvailable, setInputsAvailable] = useState(false);
  const [outputInterval, setOutputInterval] = useState(1);

  const [parameterList, setParameterList] = useState([]); // includes hidden parameter, too
  const [uiVariablesList, setUiVariablesList] = useState([]); // without hidden parameter
  const [inputsList, setInputsList] = useState([]); // includes inputs parameter, too

  const [hiddenParameterList, setHiddenParameterList] = useState([]); // hidden parameter only

  // structure
  const [menuLevel2, setMenuLevel2] = useState([[]]);
  const [parameterMenuIcons, setParameterMenuIcons] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [initialInputValues, setInitialInputValues] = useState([{}]);

  // units
  const [units, setUnits] = useState<Array<Unit>>([]);
  const [quantities, setQuantities] = useState<Array<Quantity>>([]);
  // const [isLoadingFmus, setIsLoadingFmus] = useState(true);
  const [isLoadingStructure, setIsLoadingStructure] = useState(true);
  const [fmuComponents, setFmuComponents] = useState([]);
  const [resultCharts, setResultCharts] = useState([]);

  const userDataContext = useUserDataContext();

  const fmuConfigurationVersion = useMemo(() => {
    if (resultCharts && resultCharts.length > 0) {
      return 'v2';
    }
    return 'v1';
  }, [resultCharts]);

  /**
   * @description is called to parse list of forms from structure json
   * @param uiStructureArr json data structure of main and sub menus
   */
  const getForms = (uiSetupConfigComplete: UiSetupConfig, interactiveSim = false) => {
    const formObjects = [];
    uiSetupConfigComplete.forEach((uiS) => {
      const lvl2Forms = [];

      if (uiS.name !== 'Hidden') {
        uiS.subMenu.forEach((uiSel) => {
          // standard form object if structure json entry empty
          const standardFormObj = interactiveSim
            ? {
                name: 'InteractiveForm',
              }
            : {
                name: 'StandardForm',
              };

          if (!uiSel.form) {
            lvl2Forms.push({
              name: uiSel.name,
              form: standardFormObj,
            });
          } else {
            lvl2Forms.push({
              name: uiSel.name,
              form: uiSel.form,
            });
          }
        });
        formObjects.push({
          name: uiS.name,
          forms: lvl2Forms,
        });
      }
    });
    return formObjects;
  };

  /**
   * @description is called to get list of available FMUs
   */
  const getFmuList = () => {
    httpService
      .get(`fmu/${settings.product}/`, {}, 'json', true)
      .then((response) => {
        const listFMU = response.data;
        // sort public FMUs to bottom of list
        const sortedListFMU = [...listFMU.filter((i) => !i.is_public), ...listFMU.filter((i) => i.is_public)];
        // build an array for select component with id and name, for public FMUs add '(public)'
        const selectList = sortedListFMU.map((l) => {
          if (l.is_public)
            return {
              value: l.id,
              label: `${l.display_name} (public)`,
            };
          return {
            value: l.id,
            label: l.display_name,
          };
        });
        setFmuSelectList(selectList);
        return true;
      })
      .catch((error) => error);
  };

  /**
   * @description is called to get data of one FMU and fmu componentns,
   * if no id is given, the first FMU in the list is returned
   * @param id of fmu
   */

  const getFmuData = (id?: number) => {
    if (isEmpty(fmuSelectList)) return;
    const fmuId = id || fmuSelectList[0].value;

    Promise.all([
      httpService.get(`fmu/${settings.product}/${fmuId}/`, {}, 'json', true),
      httpService.get(`fmus/${fmuId}/components/`, {}, 'json', true, 'v2'),
    ])
      .then(([fmuData, fmuComponentsData]) => {
        setSelectedFmu(fmuData.data);
        setFmuComponents(fmuComponentsData.data);
      })
      .catch((error) => error);
  };

  /**
   * @description handles the initial set of quantities and units
   */
  useQuery(
    ['quantities'],
    async () => {
      const response = await httpService.get('units/quantity/');
      return response.data;
    },
    {
      onSuccess: (data) => {
        setQuantities(data);
      },
    }
  );
  useQuery(
    ['units'],
    async () => {
      const response = await httpService.get('units/unit/');
      return response.data;
    },
    {
      onSuccess: (data) => {
        setUnits(data);
      },
    }
  );

  /**
   * @description handles initial input values for changes
   * in inputsAvailable or interactiveSimulationMode
   */
  useEffect(() => {
    if (!isEmpty(uiSetupConfig) && interactiveSimulationMode && inputsAvailable) {
      setInitialInputValues([getInitialValues(uiSetupConfig, ['Inputs'], fmuComponents)]);
    }
  }, [interactiveSimulationMode, inputsAvailable, uiSetupConfig]);

  /**
   * @description is executed on every change of fmuSelectList
   * and gets the FMU data for first FMU in list
   */
  useEffect(async () => {
    if (storedAccessToken()) {
      if (selectedFmu && selectedFmu.id && fmuSelectList.find((fmu) => fmu.value === selectedFmu.id)) {
        const fmuComponentsData = (await httpService.get(`fmus/${selectedFmu.id}/components/`, {}, 'json', true, 'v2'))
          .data;
        setFmuComponents(fmuComponentsData);

        localStorage.setItem('persistentFmuSelection', JSON.stringify(selectedFmu.id));

        let uiSetupConfigComplete = selectedFmu.ui_setup_config;

        const { inputs } = selectedFmu;
        if (inputs) uiSetupConfigComplete = inputs.concat(uiSetupConfigComplete);

        if (inputs) {
          setInputsAvailable(true);
        } else {
          setInputsAvailable(false);
        }

        setForms(getForms(uiSetupConfigComplete));
        setInteractiveForms(getForms(uiSetupConfigComplete, true)); // not ideal

        setParameterList(getVariableList(uiSetupConfigComplete, fmuComponentsData));
        setUiVariablesList(
          getVariableList(
            uiSetupConfigComplete.filter((el) => el.name !== 'Hidden'),
            fmuComponentsData
          )
        );
        if (inputs) setInputsList(getVariableList(inputs, fmuComponentsData));

        const hiddenValueStructure = uiSetupConfigComplete.filter((el) => el.name === 'Hidden');

        if (hiddenValueStructure.length > 0) {
          setHiddenParameterList(hiddenValueStructure[0].subMenu[0].variables);
        } else {
          setHiddenParameterList([]);
        }
        setVariables([
          ...selectedFmu.model_description.parameters,
          ...selectedFmu.model_description.inputs,
          ...selectedFmu.model_description.outputs,
        ]);
        setUiResultsConfig(selectedFmu.ui_results_config);
        setUiInputConfig(inputs);
        setTeams(selectedFmu.teams);
        setInteractiveSimulationMode(false);
        setResultCharts(selectedFmu.result_charts);

        const newUiSetupConfig = uiSetupConfigComplete.filter((el) => el.name !== 'Hidden');

        if (!isEmpty(newUiSetupConfig)) {
          setParameterMenuIcons(newUiSetupConfig.map((el) => el.icon || ''));
          setMenuLevel2(newUiSetupConfig.map((el) => el.subMenu.map((el2) => el2.name)));
          setInitialValues(
            getInitialValues(
              newUiSetupConfig,
              newUiSetupConfig.map((el) => el.name),
              fmuComponentsData
            )
          );
          setIsLoadingStructure(false);
        }

        setUiSetupConfig(newUiSetupConfig);
      } else {
        const cachedFmu = JSON.parse(localStorage.getItem('cachedFmu') || 'null');
        const persistentFmuSelection = JSON.parse(localStorage.getItem('persistentFmuSelection') || 'null');

        if (cachedFmu && fmuSelectList.find((fmu) => fmu.value === cachedFmu)) {
          getFmuData(cachedFmu);
        } else if (persistentFmuSelection && fmuSelectList.find((fmu) => fmu.value === persistentFmuSelection)) {
          getFmuData(persistentFmuSelection);
        } else {
          getFmuData();
        }
      }
    }
  }, [selectedFmu, fmuSelectList]);

  /**
   * @description is executed on every change of user
   * and gets the fmu list
   */
  useEffect(() => {
    if (storedAccessToken()) {
      getFmuList();
    }
  }, [userDataContext.email]);

  return (
    <StructureContext.Provider
      value={{
        getFmuList,
        getFmuData,
        selectedFmu,
        setSelectedFmu,
        fmuSelectList,
        isLoadingStructure,
        uiSetupConfig,
        uiInputConfig,
        interactiveSimulationMode,
        setInteractiveSimulationMode,
        inputsAvailable,
        menuLevel2,
        parameterMenuIcons,
        initialValues,
        initialInputValues,
        variables,
        uiResultsConfig,
        teams,
        outputInterval,
        setOutputInterval,
        forms,
        interactiveForms,
        units,
        quantities,
        parameterList,
        uiVariablesList,
        inputsList,
        hiddenParameterList,
        fmuComponents,
        fmuConfigurationVersion,
        resultCharts,
      }}
    >
      {children}
    </StructureContext.Provider>
  );
};

export const useStructureContext = (): contextType => {
  const context = useContext(StructureContext);

  if (context === undefined) {
    throw new Error('useStructureContext must be used within a StructureProvider');
  }

  return context;
};
export const useUnitHelper = (): {
  getQuantityOfUnit: (unitName: string) => Quantity | void;
  getRelatedUnits: (quantity: Quantity) => Array<Unit>;
  getAllUnits: () => Array<Unit>;
  unitConversion: (currentUnitName: string, currentValue: number, newUnitName: string) => number;
  unitsCompatible: (unit1: string, unit2: string) => boolean;
} => {
  const context = useContext(StructureContext);

  if (context === undefined) {
    throw new Error('useUnitConversion must be used within a StructureProvider');
  }

  const { getQuantityOfUnit, getRelatedUnits, getAllUnits, unitConversion, unitsCompatible } = unitsHelper;

  /**
   * @description returns value transformed to new unit,
   * if current and new unit represent the same quantity,
   * else the current value is returned.
   * @param currentUnitName
   * @param currentValue
   * @param newUnitName
   * @return {}
   */
  const prefilledUnitConversion = (currentUnitName: string, currentValue: number, newUnitName: string): number =>
    unitConversion(context.units, context.quantities, currentUnitName, currentValue, newUnitName);

  /**
   * @description checks if both units exist and belong to the same quantity
   * @param unit1
   * @param unit2
   * @return {}
   */
  const prefilledUnitsCompatible = (unit1: string, unit2: string): boolean =>
    unitsCompatible(context.units, context.quantities, unit1, unit2);

  const prefilledGetQuantityOfUnit = (unitName: string): Quantity | void =>
    getQuantityOfUnit(context.units, context.quantities, unitName);

  const prefilledGetRelatedUnits = (quantity: Quantity): Array<Unit> => getRelatedUnits(context.units, quantity);

  const prefilledGetAllUnits = (): Array<Unit> => getAllUnits(context.units);

  return {
    getQuantityOfUnit: prefilledGetQuantityOfUnit,
    getRelatedUnits: prefilledGetRelatedUnits,
    getAllUnits: prefilledGetAllUnits,
    unitConversion: prefilledUnitConversion,
    unitsCompatible: prefilledUnitsCompatible,
  };
};
export default StructureProvider;
