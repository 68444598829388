import Highcharts from 'highcharts';
import theme from '../../../theme';
import chartColors from './chartColors';
import settings from '../../../settings';

type ChartOptionsType = {
  chart: Highcharts.ChartOptions;
  lang: Highcharts.LangOptions;
  boost: Highcharts.BoostOptions;
  exporting: Highcharts.ExportingOptions;
  yAxis: Highcharts.AxisOptions;
  xAxis: Highcharts.AxisOptions;
  credits: Highcharts.CreditsOptions;
  legend: Highcharts.LegendOptions;
  colors: Array<string>;
  plotOptions: Highcharts.PlotOptions;
};

const chartOptionsTlk: ChartOptionsType = {
  chart: {
    zoomType: 'xy',
    displayErrors: false,
    backgroundColor: theme.palette.common.white,
    style: {
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamilyPlots,
      fontSize: theme.typography.fontSize.toString(),
    },
  },
  lang: {
    numericSymbols: ['', ' e6', ' e9', ' e12'],
  },
  boost: {
    enabled: true,
    useGPUTranslations: true,
    usePreallocated: true,
    debug: {
      timeSetup: false,
      timeSeriesProcessing: false,
      timeKDTree: false,
      showSkipSummary: false,
    },
  },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG', 'downloadPDF'],
        x: -25,
        y: 0,
        verticalAlign: 'bottom',
      },
    },
    chartOptions: {
      title: {
        style: {
          fontSize: theme.typography.fontSize.toString(),
        },
      },
      subtitle: {
        style: {
          fontSize: theme.typography.fontSize.toString(),
        },
      },
      xAxis: {
        title: {
          style: {
            fontSize: theme.typography.fontSize.toString(),
          },
        },
        labels: {
          style: {
            fontSize: theme.typography.fontSize.toString(),
          },
        },
      },
      yAxis: {
        title: {
          style: {
            fontSize: theme.typography.fontSize.toString(),
          },
        },
        labels: {
          style: {
            fontSize: theme.typography.fontSize.toString(),
          },
        },
      },
      legend: {
        itemStyle: {
          fontSize: theme.typography.fontSize.toString(),
        },
      },

      plotOptions: {
        pie: {
          dataLabels: {
            style: {
              fontSize: theme.typography.fontSize.toString(),
              fontWeight: theme.typography.fontWeightRegular?.toString(),
            },
          },
        },
      },
    },
  },
  xAxis: {
    title: {
      style: {
        fontSize: theme.typography.fontSize.toString(),
      },
    },
    labels: {
      style: {
        fontSize: theme.typography.fontSize.toString(),
      },
    },
    dateTimeLabelFormats: {
      month: '%b',
    },
  },
  yAxis: {
    title: {
      style: {
        fontSize: theme.typography.fontSize.toString(),
      },
    },
    labels: {
      style: {
        fontSize: theme.typography.fontSize.toString(),
      },
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    x: 0,
    y: 0,
    borderWidth: 0,
    itemStyle: {
      fontSize: theme.typography.fontSize.toString(),
    },
  },
  colors: [
    chartColors.BLUE,
    chartColors.RED,
    chartColors.GREEN,
    chartColors.LILAC,
    chartColors.ORANGE,
    chartColors.DARKGREY,
    chartColors.AQUA,
    chartColors.YELLOW,
    chartColors.PURPLE,
  ],
  plotOptions: {
    series: {
      cursor: 'pointer',
      marker: {
        enabled: false,
      },
      // threshold are set high, to disable boost for most charts, because boost enables some css
      turboThreshold: 1e9,
      // number points, where highcharts stops checking data points format
      boostThreshold: 1e9, // number points, where series enters boost mode
    },
    line: {
      lineWidth: 1,
    },
    pie: {
      dataLabels: {
        enabled: true,
        style: {
          fontSize: theme.typography.fontSize.toString(),
        },
      },
    },
    solidgauge: {
      dataLabels: {
        y: -40,
        borderWidth: 0,
        useHTML: true,
      },
    },
  },
};
const chartOptionsAerosim: ChartOptionsType = {
  ...chartOptionsTlk,
  colors: [chartColors.BLUE, chartColors.GREY, chartColors.LIGHTBLUE],
};

const getChartOptions = () => {
  if (settings.product === 'aerosim') {
    return chartOptionsAerosim;
  }

  return chartOptionsTlk;
};

const chartOptions: ChartOptionsType = getChartOptions();
export default chartOptions;
