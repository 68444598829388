// @ts-nocheck
import { evaluate } from 'mathjs';
import { isEmpty } from '../../utils/helper/helper';
import type { UiSetupConfig, Variable, Dependency } from './types';

/**
 * Returns an array of variables that belong to a specific subMenu
 * @param uiSetupConfig uiSetupConfig
 * @param lev1 level1 name
 * @param lev2 level2 name
 * @returns {*}
 */
export const getVariablesOfSubMenu = (
  uiSetupConfig: UiSetupConfig,
  lev1: string,
  lev2: string
): { alias: string; name: string; inputType: string; unit: string; dependency: Dependency; hidden?: boolean }[] => {
  const discardedVariable = {
    alias: 'Discarded Variable',
    name: 'discarded_varibale',
    unit: '-',
  };
  if (isEmpty(uiSetupConfig)) {
    return [discardedVariable];
  }

  const le1 = uiSetupConfig.find((el) => el.name === lev1);
  const subMenu = le1
    ? le1.subMenu
    : [
        {
          name: 'SubMenu',
          variables: [
            {
              alias: 'Variable',
              name: 'Variable',
              inputType: 'number',
              unit: '-',
            },
          ],
        },
      ];
  const le2 = subMenu?.find((el) => el.name === lev2);
  return le2 ? le2.variables : [discardedVariable];
};

/**
 * Get unit of variable
 * @param variableName unit of this variable gets returned
 * @returns {*}
 */
export const getVariableUnit = (variableName: string, variables: Array<Variable>) => {
  let unit = '-';

  if (isEmpty(variables)) {
    return unit;
  }

  const variable = variables.find((el) => el.name === variableName);

  if (variable) {
    unit = variable.displayUnit && variable.displayUnit !== 'null' ? variable.displayUnit : variable.unit;
    unit = unit === '1' ? '-' : unit;
  }

  return unit;
};

/**
 * @description it evaluates the dependency property of each variable
 * and returns the relevant boolean for conditional rendering
 * @param dependency object with name, type and value
 */
export const checkVariableDependency = (values: any, dependency?: Dependency) => {
  if (dependency) {
    if (dependency.type === 'hideValue') {
      if (values[dependency.name].toString() === dependency.value.toString()) return false;
    }

    if (dependency.type === 'showValue') {
      if (values[dependency.name].toString() !== dependency.value.toString()) return false;
    }
  }
  return true;
};

/**
 * @description This function maps the FMU parameter with UI parameter and
 * takes the information from structure json file. It also adds 'hidden' parameters,
 * but not stopt_time and output_interval
 * @param values from Formik
 * @param uiVariablesList list of variables from structure json file
 * @returns {*}
 */
export const getMappedFmuUiValues = (
  values: any,
  uiVariablesList: Array<Variable>,
  hiddenParameterList: Array<any>,
  fmuComponents?: Array<any>
) => {
  const mappedFmuParameter = {};
  const mappedFmuInputs = {};
  // get parameters and values that are in UI and need to be passed to FMU
  // for uiParametersList a backwards compatibility is a added if uiType is not defined
  const fmuParameterList = uiVariablesList.filter(
    (parameter) =>
      (parameter.uiType === 'fmuParameter' || !parameter.uiType) &&
      (!parameter.dependency ||
        checkVariableDependency(values, parameter.dependency) ||
        !parameter.dependency.ignoreIfHidden)
  );

  const fmuInputsList = uiVariablesList.filter((el) => el.uiType === 'fmuInput');
  const componentSelectionList = uiVariablesList
    .filter(
      (el) => el.inputType === 'ComponentSelection' && (el.uiType === 'fmuParameter' || el.uiType === 'uiParameter')
    )
    .filter(
      (parameter) =>
        parameter.inputType === 'ComponentSelection' &&
        (parameter.uiType === 'fmuParameter' || parameter.uiType === 'uiParameter') &&
        (!parameter.dependency ||
          checkVariableDependency(values, parameter.dependency) ||
          !parameter.dependency.ignoreIfHidden)
    );

  // add component-database values without the selector to parameter object
  componentSelectionList.forEach((parameter) => {
    const fmuComponent = fmuComponents.find((el) => el.name === parameter.fmuComponentName);

    if (fmuComponent) {
      const componentInstance = fmuComponent.instances.find((el) => el.id === values[parameter.name]);

      if (componentInstance) {
        for (const [key, value] of Object.entries(componentInstance.parameters || {})) {
          if (Array.isArray(value)) {
            value.forEach((arrVal, arrValId) => {
              const newKey = fmuComponent.name
                ? `${fmuComponent.name}:${key}[${arrValId + 1}]`
                : `${key}[${arrValId + 1}]`;
              mappedFmuParameter[newKey] = arrVal;
            });
          } else {
            const newKey = fmuComponent.name ? `${fmuComponent.name}:${key}` : key;
            mappedFmuParameter[newKey] = value;
          }
        }
      }
    }
  });

  // add parameters with uiType fmuParameter
  fmuParameterList.forEach((parameter) => {
    if (parameter.inputType === 'variableInput') {
      // TODO: define code below as function?
      const uiV = values[parameter.name];
      parameter.variableInput.names.forEach((fmuParamList, idxParamList) => {
        fmuParamList.forEach((fmuParam, idxParam) => {
          if (idxParam < uiV.length - 1) {
            mappedFmuParameter[fmuParam] = uiV[idxParam][parameter.variableInput.columnNames[idxParamList]];
          } else if (parameter.variableInput.columnNames[idxParamList] !== 'time') {
            const lastUiValue = uiV[uiV.length - 2][parameter.variableInput.columnNames[idxParamList]];
            const lastTimeValue = uiV[uiV.length - 2].time;
            const timeMax = uiV[uiV.length - 1].time;
            const dummyTimeValue = lastTimeValue + (idxParam / 10) * (timeMax - lastTimeValue);
            mappedFmuParameter[fmuParam] = lastUiValue;
            mappedFmuParameter[parameter.variableInput.names[0][idxParam]] = dummyTimeValue;
          }
        });
      });
    } else if (parameter.inputType === 'Table') {
      // FMU required indices of table attached to name
      values[parameter.name].forEach((array1, rowId) => {
        array1.forEach((el, colId) => {
          // Modelica arrays start at 1, not 0
          mappedFmuParameter[`${parameter.name}[${rowId + 1},${colId + 1}]`] = el;
        });
      });
    } else if (!['start_time', 'stop_time', 'output_interval', 'simulation_name'].includes(parameter.name)) {
      // TODO find better solution to handle parameters that must not be included in FMU parameters
      // don't add stop_time, output_interval and simulation_name to FMU parameters
      mappedFmuParameter[parameter.name] = values[parameter.name];
    }
  });

  // add hidden values to parameter object
  hiddenParameterList.forEach((el) => {
    // don't add start_time, stop_time and output_interval to FMU parameters
    if (el.name !== 'start_time' && el.name !== 'stop_time' && el.name !== 'output_interval') {
      mappedFmuParameter[el.name] = el.start;
    }
  });

  fmuInputsList.forEach((input) => {
    mappedFmuInputs[input.name] = values[input.name];
  });

  return {
    parametersFMU: mappedFmuParameter,
    inputsFMU: mappedFmuInputs,
  };
};

export const getVariableList = (uiSetupConfigComplete: UiSetupConfig, fmuComponents: Array<any> = []) =>
  uiSetupConfigComplete
    .flatMap((menu) => menu.subMenu)
    .flatMap((subMenu) =>
      subMenu?.variables.flatMap((variable) => {
        if (variable.inputType === 'ComponentSelection') {
          const componentSelectionVar = [];
          const fmuComponent = fmuComponents?.find((c) => c.name === variable.fmuComponentName);

          if (fmuComponent) {
            const parameterDefinitions = fmuComponent.component.merged_parameter_definitions;
            Object.keys(parameterDefinitions).forEach((parameterName) => {
              componentSelectionVar.push({
                ...parameterDefinitions[parameterName],
                inputType: parameterDefinitions[parameterName].type === 'Array' ? 'TextField' : 'ButtonField',
                name: `${variable.fmuComponentName}:${parameterName}`,
              });
            });
          }

          // Pushing the component selection variable at the end of the list, makes sure that when setting initial
          // values, the parameters' start values are overwritten by the default component instance selection parameters
          componentSelectionVar.push(variable);
          return componentSelectionVar;
        }

        return [variable];
      })
    );

export const getInitialValues = (
  uiSetupConfigComplete: UiSetupConfig,
  mainMenuItems: Array<string>,
  fmuComponents: Array<any>
) => {
  const initValues = {};
  // filter uiStructureArr with mainMenuItems
  const uiStructureFiltered = uiSetupConfigComplete.filter((j) => mainMenuItems.includes(j.name));
  // get a flat variable list
  const variableList = getVariableList(uiStructureFiltered, fmuComponents);

  // go through variables list and write initial values for Formik
  variableList.forEach((v: Variable) => {
    if (v.inputType === 'variableInput') {
      if (v.variableInput) {
        if (v.variableInput.start) {
          const startValues = [];
          v.variableInput.start.forEach((el, indStartValues) => {
            const rowValues = {
              id: indStartValues,
            };
            v.variableInput.columnNames.forEach((name, indColumnName) => {
              rowValues[name] = el[indColumnName];
            });
            startValues.push(rowValues);
          });
          initValues[v.name] = startValues;
        }
      }
    } else if (['Table', 'InputTable'].includes(v.inputType)) {
      initValues[v.name] = v.arrays.start;
      initValues[`${v.name}_dataSource`] = v.dataSource;
    } else if (v.inputType === 'ComponentSelection') {
      const fmuComponent = fmuComponents?.find((c) => c.name === v.fmuComponentName);
      const defaultInstance = fmuComponent?.default_instance || fmuComponent?.instances[0].id;
      initValues[v.name] = defaultInstance;
      if (fmuComponent) {
        const params = fmuComponent.instances.find((i) => i.id === defaultInstance).parameters;
        for (const [key, value] of Object.entries(params)) {
          initValues[`${v.fmuComponentName}:${key}`] = value;
        }
      }
    } else if (v.inputType === 'InactiveCalc') {
      const calcVariables = Object.entries(v.calculation.variables).map((entry) => {
        const [formuleName, variableName] = entry;
        return {
          formuleName,
          variableName,
        };
      });
      const scope = {};
      calcVariables.forEach((calcVar) => {
        const variableValue = variableList.find((variable) => variable.name === calcVar.variableName);
        scope[calcVar.formuleName] = variableValue.start;
        return 0;
      });
      initValues[v.name] = evaluate(v.calculation.formula, scope);
    } else if (v.inputType !== 'Header') {
      // make sure that 'Header' are not added to initialValues
      initValues[v.name] = v.start;
    }
  });
  return initValues;
};

export default getVariablesOfSubMenu;
